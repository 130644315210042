<template>
  <div>
    <transition name="bubble">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ContainerNotifyHac',
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Сети",
      "plan": "Тарифный план",
      "stats": "Статистика"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "nav": {
      "notify": "Уведомления",
      "config": "Настройки"
    },
    "newServer": "Новая сеть",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
//.notify {
//  display: flex;
//  flex-direction: column;
//  min-size(100%);
//  flex: 1 1 100%;
//  &-header {
//    +breakpoint(sm-and-up) {
//      //flexy(space-betweeb);
//      flexy(space-between, center);
//      //flex-direction: column;
//    }
//
//    //&__content {
//    //  flexy(space-between, center);
//
//    +breakpoint(sm-and-up) {
//      justify-content: flex-start;
//      margin-right: 1.5rem;
//      //margin-top: 1.75rem;
//      margin-bottom: 1.5rem;
//      flex: 1 1 auto;
//      //}
//    }
//  }
//  &-info {
//    +breakpoint(sm-and-up) {
//      flexy(space-betweeb, center);
//    }
//
//    &__content {
//      flexy(space-between, center);
//
//      +breakpoint(sm-and-up) {
//        justify-content: flex-start;
//        margin-right: 1.5rem;
//        flex: 1 1 auto;
//      }
//    }
//
//    &__loader {
//      flexy(center, center);
//      flex: 1 1 auto;
//    }
//
//    &__create {
//      flex: 0 0 auto;
//      margin-top: 1.5rem;
//      max-width: 160px;
//
//      +breakpoint(sm-and-up) {
//        margin: 0 0 0 auto;
//      }
//    }
//  }
//
//  &-mode {
//    flexy(flex-end, center);
//    margin-left: auto;
//
//    +breakpoint(sm-and-up) {
//      justify-content: flex-start;
//      margin-left: 1.5rem;
//    }
//
//    &__btn {
//      & + & {
//        margin-left: 1rem;
//      }
//    }
//  }
//
//  &-content {
//    margin-top: 1.5rem;
//
//    +breakpoint(sm-and-up) {
//      margin-top: 2rem;
//    }
//    +breakpoint(md-and-up) {
//      margin-top: 2.5rem;
//    }
//  }
//}
</style>
